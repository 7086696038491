// -----------------------------------// 
// HP SLIDER
// -----------------------------------//

.body_4563 #slider {
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 700px;
    min-height: 700px;
  }

  // &:after {
  //   @include before(100%, 500px);
  //   bottom: 0;
  //   left: 0;
  //   background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
  //   // background: url(/i/design/gradient-bottom.png) repeat-x bottom left;
  // }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 550px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 450px;
    min-height: 450px;
  }

  &:before {
    @include before(100%, 350px);
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
    opacity: .5;
    // background: url(/i/design/gradient.png) repeat-x top left;

    @media only screen and (max-width: 990px) {
      opacity: 1;
    }
  }
}

.backstretch {
  position: relative;

  // &:before {
  //   @include before(100%, 500px);
  //   bottom: 0;
  //   left: 0;
  //   background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
  //   // background: url(/i/design/gradient-bottom.png) repeat-x bottom left;
  // }

  img {
    top: 0px !important;
    animation: scale 25s ease forwards infinite;
  }

  @keyframes scale {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.25);
    }
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  z-index: 99;
  color: #fff;
  line-height: 1;
  padding: 20px;
  font-size: 2.5vw;
  background: #2e2e2e;
  text-transform: uppercase;
  font-weight: 600;

  span {
    color: #95c4a2;
  }

  @media only screen and (max-width: 1800px) {
    font-size: 45px;
  }

  @media only screen and (max-width: 1500px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 1350px) {
    font-size: 35px;
  }

  @media only screen and (max-width: 990px) {
    // @include posreset;
    font-size: 30px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 25px;
  }
}